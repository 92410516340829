import { createRoot } from "react-dom/client";
import { JsDataGlobalNotice } from "../shared/components/GlobalNotice";
import type { CollectFeedbackQuestionType } from "../shared/types";
import CollectFeedback from "./CollectFeedback";

export interface IQuestion {
    id: string;
    nr: number;
    type: CollectFeedbackQuestionType;
    question: string;
    translation: string | null;
    leftLabel: string | null;
    rightLabel: string | null;
    leftTranslation: string | null;
    rightTranslation: string | null;
}

export interface IData {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string | null;
    heading: string;
    description: string | null;
    questions: IQuestion[];
    open: boolean;
    anonymous: boolean;
}

// init function
export default function init() {
    const data = JSON.parse(document.getElementById("collect-feedback-data").innerHTML) as IData;

    const container = document.getElementById("collect-feedback-page");
    const root = createRoot(container);
    root.render(
        <>
            <JsDataGlobalNotice />
            <CollectFeedback data={data} />
        </>,
    );
}
