import Linkify from "linkify-react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import LikertPreview from "../../shared/components/LikertPreview";
import SliderPreview from "../../shared/components/SliderPreview";
import t from "../../shared/translations";
import type { ITaskThreadItem } from "./OutsideFeedback";

interface IProps {
    tti: ITaskThreadItem;
}

const Box = styled.div`
    background-color: ${currentColors.background};
    border: 1px solid ${currentColors.border};
    border-radius: 1rem;
    margin-bottom: 0.8rem;
    padding: 0.6rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
`;

interface ICheckboxAnswer {
    $checked: boolean;
}

const CheckboxAnswer = styled.label<ICheckboxAnswer>`
    color: ${currentColors.text};
    font-size: 1rem;
    cursor: default;
    font-weight: ${({ $checked }) => ($checked ? "bold" : "normal")};
`;

const TextboxAnswer = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const FileLogo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;

const Hr = styled.hr`
    border-color: ${currentColors.border};
`;

function ThreadItemAnswer({ tti }: IProps): JSX.Element {
    if (tti.type !== "answer") {
        return null;
    }
    const name = tti.person ? `${tti.person.firstName} ${tti.person.lastName}` : "";
    const numAns = tti.taskAnswers.length;
    return (
        <Box>
            <h4>{t("public.outside-feedback.heading-submitted-answer", { count: numAns, name })}</h4>
            {tti.taskAnswers.map((answer, index) => {
                const last = index + 1 === tti.taskAnswers.length;
                if (answer.answerType === "checkbox") {
                    return (
                        <div key={answer.id}>
                            {answer.questionContent && <h5>{answer.questionContent}</h5>}
                            {answer.checkboxAnswers.map((answer) => (
                                <CheckboxAnswer $checked={answer.checked} key={answer.id}>
                                    <input type="checkbox" checked={answer.checked} disabled /> {answer.content}
                                </CheckboxAnswer>
                            ))}
                            {!last && <Hr />}
                        </div>
                    );
                }
                if (answer.answerType === "likert") {
                    return (
                        <div key={answer.id}>
                            {answer.questionContent && <h5>{answer.questionContent}</h5>}
                            {answer.taskLikertAnswers.map((ans) => (
                                <LikertPreview
                                    key={ans.id}
                                    statement={ans.statement}
                                    leftLabel={ans.leftLabel}
                                    rightLabel={ans.rightLabel}
                                    selected={ans.value}
                                />
                            ))}
                            {!last && <Hr />}
                        </div>
                    );
                }
                if (answer.answerType === "impact-tracker") {
                    return (
                        <div key={answer.id}>
                            {answer.questionContent && <h5>{answer.questionContent}</h5>}
                            {answer.impactTrackerAnswerSet.impactLikertAnswers.map((ans) => (
                                <SliderPreview
                                    key={ans.id}
                                    statement={ans.statement}
                                    leftLabel={ans.leftLabel}
                                    rightLabel={ans.rightLabel}
                                    value={ans.value}
                                    showValue
                                />
                            ))}
                            {!last && <Hr />}
                        </div>
                    );
                }
                if (answer.answerType === "textbox") {
                    return (
                        <div key={answer.id}>
                            {answer.questionContent && <h5>{answer.questionContent}</h5>}
                            <TextboxAnswer>
                                <Linkify options={{ nl2br: true }} tagName="p">
                                    {answer.answerContent}
                                </Linkify>
                            </TextboxAnswer>
                            {!last && <Hr />}
                        </div>
                    );
                }
                if (answer.answerType === "file") {
                    const taskAnswerFile = answer.taskAnswerFile;
                    return (
                        <div key={answer.id}>
                            {answer.questionContent && <h5>{answer.questionContent}</h5>}
                            <a href={taskAnswerFile.src} target="_blank" rel="noreferrer">
                                {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                {taskAnswerFile.name}
                            </a>
                            {!last && <Hr />}
                        </div>
                    );
                }
            })}
        </Box>
    );
}

export default ThreadItemAnswer;
