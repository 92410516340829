import { type JSX, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import RenderMark from "../../shared/components/RenderMark";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import t from "../../shared/translations";
import { type IDispatch, type IState, closeModal } from "../state";
import ExampleTask from "./ExampleTask";
import type { IExamplePath } from "./ExamplesModal";
import MilesChart from "./MilesChart";
import PathLength from "./PathLength";
import PathPrice from "./PathPrice";
import ThemesChart from "./ThemesChart";

interface IProps {
    path: IExamplePath;
    toggleOpen: (id: string) => void;
    isOpen: boolean;
}

const Container = styled.div`
    margin-bottom: 0.5rem;
    border: 1px solid ${currentColors.text};
    border-radius: 1rem;
    overflow: hidden;
`;

const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr auto 20px;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    gap: 0.5rem;
    h3 {
        margin: 0;
    }
    button {
        margin: 0;
    }
`;

const Body = styled.div`
    padding: 0 0.5rem 0.5rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    gap: 1rem;

    @media (min-width: 740px) {
        grid-template-columns: 1fr auto;
    }
`;

const Button = styled.button`
    margin: 0;
    margin-right: 20px;
    padding: 0.5rem 1rem;
`;

const SummariesCol = styled.div`
    place-items: center;
    display: grid;
    gap: 0.5rem;
`;

const TasksCol = styled.div`
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;

const StyledMark = styled(RenderMark)`
    margin: 0;
    grid-column: 1/-1;
    > * {
        margin-bottom: 0;
    }
`;

function ExamplePath({ path, toggleOpen, isOpen }: IProps): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch<IDispatch>();
    const numTasksOnPath = useSelector((state: IState) => state.builder.tasks.length);
    const loadPath = () => {
        if (numTasksOnPath > 0) {
            if (!window.confirm(t("public.builder.confirm-load-example"))) {
                return;
            }
        }
        navigate(`/path-builder/${path.locale}/build/${path.publicId}`);
        dispatch(closeModal());
    };
    const tasks = path.builderTaskOnPaths.map((top) => top.builderTask);
    return (
        <Container>
            <Header onClick={() => toggleOpen(path.id)}>
                <h3>{path.name}</h3>
                <Button onClick={loadPath}>{t("public.builder.button-load-example")}</Button>
                <PlusMinus open={isOpen} size="1.2rem" />
                {path.description && <StyledMark content={path.description} />}
            </Header>
            {isOpen && (
                <Body>
                    <TasksCol>
                        {tasks.map((task) => (
                            <ExampleTask key={task.id} task={task} />
                        ))}
                    </TasksCol>
                    <SummariesCol>
                        {path.showThemes && <ThemesChart tasks={tasks} maxWidth="100%" />}
                        {path.showMiles && <MilesChart tasks={tasks} maxWidth="200px" />}
                        {path.showLength && <PathLength tasks={tasks} width="100%" />}
                        {path.showPrice && <PathPrice tasks={tasks} width="100%" />}
                    </SummariesCol>
                </Body>
            )}
        </Container>
    );
}

export default memo(ExamplePath);
