import styled from "styled-components";
import { currentColors } from "../../shared/colors";

export const Wrapper = styled.div`
    display: grid;
    padding: 0 15px;
    min-height: 100vh;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
`;

export const Main = styled.main`
    display: grid;
    max-width: 1280px;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: 100%;
    padding-top: 0.5rem;
    justify-self: center;
    grid-template-areas: 
        "header"
        "info"
        "nav"
        "charts";

    @media (min-width: 800px) {
        grid-gap: 1rem;
        padding-top: 1rem;
        grid-template-columns: 280px 1fr;
        grid-template-areas: 
            "header header"
            "info info"
            "nav charts";
    }
    @media (min-width: 900px) {
        grid-gap: 1.5rem 1rem;
        grid-template-columns: 320px 1fr;
    }
    @media (min-width: 1000px) {
        grid-gap: 2rem 1rem;
        grid-template-columns: 360px 1fr;
    }
    @media (min-width: 1100px) {
        grid-template-columns: 400px 1fr;
    }
`;

export const NoReport = styled.div`
    @media (min-width: 800px) {
        grid-column: span 2;
    }
`;

export const H1 = styled.h1`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    grid-area: header;
    margin: 0;
    gap: 0.5rem;
    @media (min-width: 600px) {
        gap: 1rem;
        flex-direction: row;
    }
`;

export const InfoBox = styled.div`
    padding: 0.6rem;
    border: 1px solid ${currentColors.text};
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.1rem;
    flex-direction: column;
    grid-area: info;
    
    @media (min-width: 600px) {
        flex-direction: row;
    }
    
    @media (min-width: 800px) {
        padding: 1rem;
        font-size: 1.4rem;
    }
`;

export const LogoImg = styled.img`
    width: 290px;
    max-width: 100%;
    height: 60px;
    object-fit: scale-down;
`;

export const Nav = styled.nav`
    border: 1px solid ${currentColors.border};
    border-radius: 1rem;
    align-self: flex-start;
    display: grid;
    padding: 1rem;
    gap: 0.3rem;
    grid-area: nav;

    @media (min-width: 800px) {
        position: sticky;
        top: 0.5rem;
    }
`;

export const NavAnchor = styled.a`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
    svg {
        width: 2.2rem;
        height: 2.2rem;
        flex-shrink: 0;
    }
    @media (min-width: 800px) {
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
    @media (min-width: 900px) {
        font-size: 1.1rem;
    }
`;

export const ChartCol = styled.div`
    display: grid;
    gap: 0.5rem;
    grid-area: charts;
    @media (min-width: 800px) {
        gap: 1rem;
    }
`;

export const ChartBox = styled.div`
    border: 1px solid ${currentColors.text};
    padding: 0.2rem;
    max-width: 100%;
    
    @media (min-width: 800px) {
        padding: 0.4rem;
    }
    @media (min-width: 900px) {
        padding: 0.6rem;
    }
    @media (min-width: 1000px) {
        padding: 0.8rem;
    }
    @media (min-width: 1100px) {
        padding: 1rem;
    }
`;

export const H2 = styled.h2`
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 0.4rem;
    @media (min-width: 800px) {
        font-size: 1.4rem;
        margin-bottom: 0.6rem;
    }
    @media (min-width: 900px) {
        margin-bottom: 0.8rem;
        font-size: 1.6rem;
    }
`;
