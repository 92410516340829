import type { JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";

interface IOwnProps {
    label?: string;
    placeholder?: string;
    name: string;
    className?: string;
    required?: boolean;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

interface IShowError {
    $error: boolean;
}

const StyledInput = styled.input<IShowError>`
    &[type="text"], &[type="password"], &:not([type]) {
        border: 1px solid ${currentColors.text};
        border-bottom-width: ${({ $error }) => ($error ? 0 : 1)};
        box-shadow: none;
        border-radius: 0.65rem 0.65rem ${({ $error }) => ($error ? "0 0" : "0.65rem 0.65rem")};
        margin-bottom: ${({ $error }) => ($error ? "0" : "1rem")};

        &:focus, &:active {
            border-color: ${currentColors.text};
        }
    }
`;

const ErrorLabel = styled.small`
    display: block;
    font-size: .75rem;
    font-style: italic;
    font-weight: normal;
    padding: .375rem .5625rem .5625rem;
    background: ${currentColors.error};
    color: white;
    border-radius: 0 0 0.65rem 0.65rem;
    margin-bottom: 1rem;
    border: 1px solid rgb(34,34,34);
    border-top-width: 0;
`;

function Input<T = string>({ name, label, placeholder, className, required, ...props }: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLInputElement>(name, props);
    const showError = touched && !!error;
    return (
        <div className={className}>
            <label>
                {label && `${label}${required ? "*" : ""}: `}
                <StyledInput {...input} placeholder={placeholder} $error={showError} />
                {showError && <ErrorLabel>{error}</ErrorLabel>}
            </label>
        </div>
    );
}

export default Input;
