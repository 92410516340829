import { memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { builderThemeColor, currentColors } from "../colors";
import t from "../translations";
import type { TaskIcon as TaskIconType, TaskTheme } from "../types";
import RenderMark from "./RenderMark";
import TaskIcon from "./icons/TaskIcon";
import CalendarIcon from "./icons/taskIcons/CalendarIcon";
import NetworkOfPeopleIcon from "./icons/taskIcons/NetworkOfPeopleIcon";
import PersonShadowIcon from "./icons/taskIcons/PersonShadowIcon";
import GlassesIcon from "./icons/teamScanIcons/GlassesIcon";

interface IProps {
    task: {
        icon: TaskIconType;
        miles: number;
        shortTitle: string;
        shortDescription: string;
        description: string | null;
        isGroupTask: boolean;
        isMedia: boolean;
        isPeopleEvent: boolean;
        isLecture: boolean;
        themes: {
            theme: TaskTheme;
            value: number;
        }[];
    };
}

const Wrapper = styled.div`
    margin: 0 auto;
`;

const MainRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "InfoBox"
        "DescriptionSection"
    ;
    gap: 0.5rem;

    @media (min-width: 740px) {
        grid-template-columns: 1fr 270px;
        grid-template-areas:
            "DescriptionSection InfoBox";
    }
    @media (min-width: 1000px) {
        gap: 1rem;
        grid-template-columns: 1fr 320px;
    }
`;

const DescriptionSection = styled.section`
    grid-area: DescriptionSection;
    border-radius: 1rem;
    border: 1px solid ${currentColors.border};
    padding: 0.6rem;
    order: 1;
    @media (min-width: 740px) {
        padding: 1rem;
        order: 0;
    }
`;

const StatsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
`;

const ThemeBox = styled.div`
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    gap: 4px;
`;

const IconsCol = styled.div`
    display: grid;
    gap: 3px;
`;

const IconItem = styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.2;
    gap: 4px;
    svg {
        width: 32px;
        height: 32px;
    }
`;

interface IThemeValue {
    $theme: TaskTheme;
}

const ThemeValue = styled.div<IThemeValue>`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${({ $theme }) => builderThemeColor[$theme]};
`;

const InfoBox = styled.section`
    grid-area: InfoBox;
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
    padding: 0.6rem;
    background-color: ${currentColors.background};
    border: 1px solid ${currentColors.border};
    display: grid;
    place-items: center;
    align-items: start;
    align-content: start;

    @media (min-width: 740px) {
        width: 270px;
        padding: 1rem;
    }
    @media (min-width: 1000px) {
        width: 320px;
    }
`;

const StyledTaskIcon = styled(TaskIcon)`
    width: 180px;
    height: 180px;
`;

const LabeledInfo = styled.div`
    display: grid;
    place-items: center;
    margin-bottom: 0.5rem;
    text-align: center;
    & > div:first-child {
        font-size: 1.5rem;
    }
    & > div:last-child {
        font-size: 3rem;
        line-height: 1;
    }
`;

function BuilderTaskDescription({ task }: IProps): JSX.Element {
    const anyType = task.isGroupTask || task.isMedia || task.isPeopleEvent || task.isLecture;
    return (
        <Wrapper>
            <h1>{task.shortTitle}</h1>
            <MainRow>
                <DescriptionSection>
                    <StatsRow>
                        {task.themes.length > 0 && (
                            <div>
                                <h4>{t("shared.builder-task-description.heading-themes")}</h4>
                                {task.themes.map((theme) => (
                                    <ThemeBox key={theme.theme}>
                                        <ThemeValue $theme={theme.theme} />
                                        <div>{t(`shared.task-themes.${theme.theme}`)}</div>
                                    </ThemeBox>
                                ))}
                            </div>
                        )}
                        {anyType && (
                            <div>
                                <h4>{t("shared.builder-task-description.heading-task-type")}</h4>
                                <IconsCol>
                                    {task.isGroupTask && (
                                        <IconItem>
                                            <PersonShadowIcon />
                                            {t("shared.builder-task-description.label-is-group-task")}
                                        </IconItem>
                                    )}
                                    {task.isMedia && (
                                        <IconItem>
                                            <GlassesIcon />
                                            {t("shared.builder-task-description.label-is-media")}
                                        </IconItem>
                                    )}
                                    {task.isPeopleEvent && (
                                        <IconItem>
                                            <NetworkOfPeopleIcon />
                                            {t("shared.builder-task-description.label-is-people-event")}
                                        </IconItem>
                                    )}
                                    {task.isLecture && (
                                        <IconItem>
                                            <CalendarIcon />
                                            {t("shared.builder-task-description.label-is-lecture")}
                                        </IconItem>
                                    )}
                                </IconsCol>
                            </div>
                        )}
                    </StatsRow>
                    {task.shortDescription && <strong>{task.shortDescription}</strong>}
                    {task.description && <RenderMark content={task.description} />}
                </DescriptionSection>
                <InfoBox>
                    <StyledTaskIcon icon={task.icon} />
                    <LabeledInfo>
                        <div>{t("shared.builder-task-description.label-miles")}</div>
                        <div>{task.miles}</div>
                    </LabeledInfo>
                </InfoBox>
            </MainRow>
        </Wrapper>
    );
}

export default memo(BuilderTaskDescription);
