import styled from "styled-components";
import { currentColors } from "../../shared/colors";

export const Wrapper = styled.div`
    display: grid;
    padding: 0 15px;
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
`;

export const Main = styled.main`
    display: grid;
    max-width: 1280px;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: 100%;
    padding-top: 0.5rem;
    justify-self: center;
`;

export const Header = styled.header`
    padding-top: 15px;
    margin-bottom: 2rem;
`;

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 3rem;
    justify-content: center;
`;

export const QuestionBox = styled.div`
    flex-basis: 300px;
    max-width: 500px;
    min-width: 290px;
    flex-grow: 1;
    display: grid;
    justify-items: center;
    align-content: flex-start;
    gap: 2rem;
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid ${currentColors.border};
    background-color: ${currentColors.background};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    svg {
        width: 6rem;
        height: 6rem;
    }
    h2 {
        margin: 0;
        font-size: 1.6rem;
        text-align: center;
    }
    textarea {
        margin: 0;
        border: 1px solid ${currentColors.grey3};
        padding: 0.5rem;
        border-radius: 1rem;
        min-height: 6rem;
        resize: vertical;
    }
`;

export const ErrorLabel = styled.span`
    display: inline-block;
    background-color: ${currentColors.error};
    color: white;
    padding: 2px 15px;
    text-transform: capitalize;
    font-size: 1.1rem;
    border-radius: 10px;
`;

export const SubmitCol = styled.div`
    display: grid;
    gap: 1rem;
    justify-content: center;
`;

export const FinishedBox = styled.div`
    display: grid;
    gap: 3rem;
    justify-content: center;
    align-content: center;
    justify-items: center;
    svg {
        width: 200px;
        height: 200px;
    }
    h2 {
        font-size: 1.6rem;
    }
    > * {
        margin: 0;
    }
`;
