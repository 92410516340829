import { type JSX, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useHasRole } from "../CurrentPerson";
import Modal from "../Modal";
import { type IDispatch, type IState, initEmptyBuilderPath, openModal } from "../state";
import type { IBuilderParams } from "./BuilderRoutes";
import ExamplesModal from "./ExamplesModal";
import NameEditor from "./NameEditor";

const pdfDataSelector = createSelector(
    (state: IState, _: string) => state.builder,
    (_: IState, locale: string) => locale,
    (builder, locale) => ({
        name: builder.name,
        locale,
        showThemes: builder.showThemes,
        showMiles: builder.showMiles,
        showLength: builder.showLength,
        showPrice: builder.showPrice,
        tasks: builder.tasks.map((task) => task.id),
    }),
);

const HeaderWrapper = styled.header`
    margin: 0.5rem 0 0.5rem 0;
    button {
        padding: 0.45rem 0.9rem;
    }

    h1, h2, button, input {
        margin: 0;
    }

    h2, input {
        height: 48px;
    }

    input {
        font-size: 2.3125rem;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -4px;
    margin-bottom: 1rem;
    @media (min-width:  768px) {
        margin-bottom: 1.5rem;
    }
    > * {
        margin-left: 4px;
        margin-right: 4px;
    }
`;

const UpperRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-bottom: 1rem;
    align-items: center;
    @media (min-width:  530px) {
        grid-template-columns: 1fr auto;
    }
    @media (min-width:  768px) {
        margin-bottom: 1.5rem;
        grid-template-columns: 1fr auto 1fr;
    }
`;

const LogoContainer = styled.div`
    @media (min-width:  530px) {
        grid-column: 1/-1;
    }
    @media (min-width:  768px) {
        grid-column: 1/2;
    }
`;

const Space = styled.div`
    flex-grow: 1;
`;

const NameRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const ButtonRow = styled.div`
    display: flex;
    margin-bottom: 0;
    gap: 0.5rem;
    button {
        margin: 0;
    }
`;

const AnchorButton = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin-top: 0.2rem;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;
    margin-left: auto;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

const EditButton = styled.button`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin-top: 0.2rem;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;
    margin-left: auto;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

const HeaderLogo = styled.img`
    max-height: 3.5rem;
    max-width: 390px;
    object-fit: scale-down;
`;

function Header(): JSX.Element {
    const navigate = useNavigate();
    const isAdmin = useHasRole(["admin"]);
    const dispatch = useDispatch<IDispatch>();
    const name = useSelector((state: IState) => state.builder.name);
    const ref = useRef<HTMLHeadingElement>(null);
    const [editName, setEditName] = useState(false);
    const { lang } = useParams<IBuilderParams>();
    const pdfData = useSelector((state: IState) => pdfDataSelector(state, lang));

    const download = (): void => {
        const strData = encodeURIComponent(JSON.stringify(pdfData));
        const url = `/pdf/download/builder-path?data=${strData}`;
        window.open(url);
    };

    const clear = () => {
        if (window.confirm(t("public.builder.confirm-clear-path"))) {
            dispatch(initEmptyBuilderPath(t("public.builder.default-pathname")));
            navigate(`/path-builder/${lang}/build`);
        }
    };

    const logoSrc = loadJSData().settings.logo_src;
    return (
        <HeaderWrapper>
            <UpperRow>
                <LogoContainer>
                    <Link to={`/path-builder/${lang}/start`}>
                        <HeaderLogo src={logoSrc} />
                    </Link>
                </LogoContainer>
                <h1>{t("public.builder-start.heading")}</h1>
                <div style={{ textAlign: "right" }}>
                    {isAdmin && <AnchorButton href="/admin">{t("public.builder.button-admin")}</AnchorButton>}
                </div>
            </UpperRow>
            <Row>
                <NameRow>
                    {editName ? (
                        <NameEditor name={name} setEditName={setEditName} width={ref.current?.offsetWidth || 300} />
                    ) : (
                        <>
                            <h2 ref={ref}>{name}</h2>
                            {isAdmin && (
                                <EditButton onClick={() => setEditName(true)}>
                                    {t("public.builder.button-edit-name")}
                                </EditButton>
                            )}
                        </>
                    )}
                </NameRow>
                <Space />
                <ButtonRow>
                    {isAdmin && (
                        <button onClick={() => dispatch(openModal("Builder/SavePath"))} type="button">
                            {t("public.builder.button-save")}
                        </button>
                    )}
                    {isAdmin && (
                        <button onClick={clear} type="button">
                            {t("public.builder.button-clear")}
                        </button>
                    )}
                    <button onClick={() => dispatch(openModal("Builder/Examples"))} type="button">
                        {t("public.builder.button-examples")}
                    </button>
                    <button onClick={download} type="button">
                        {t("public.builder.button-download-pdf")}
                    </button>
                </ButtonRow>
                <Modal modal="Builder/Examples">
                    <ExamplesModal />
                </Modal>
            </Row>
        </HeaderWrapper>
    );
}

export default Header;
