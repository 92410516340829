import styled from "styled-components";
import { currentColors } from "../../shared/colors";

export const Wrapper = styled.div`
    display: grid;
    padding: 0 15px;
    min-height: 100vh;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
`;

export const Main = styled.main`
    display: grid;
    max-width: 1400px;
    width: 100%;
    grid-gap: 3rem;
    grid-template-columns: 100%;
    justify-self: center;
`;

export const H1 = styled.h1`
    padding: 0.5rem;
    margin: 0;
    margin-bottom: 2rem;
    background-color: ${currentColors.header};
    text-align: center;
    color: white;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
`;

export const H2 = styled.h2`
    text-align: center;
`;

export const Box = styled.div`
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${currentColors.border};
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.4rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }
`;
