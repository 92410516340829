import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";

const FooterWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    border-top: 1px solid ${currentColors.text};
    padding: 5px 0;
    max-width: 1024px;
    width: 100%;
    gap: 10px;
    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    > * {
        text-align: center;
        font-size: 0.9em;
    }
`;

const FooterLogo = styled.img`
    max-height: 2rem;
    max-width: 290px;
    object-fit: scale-down;
`;

function Footer(): JSX.Element {
    const logoSrc = loadJSData().settings.logo_src;
    return (
        <FooterWrapper>
            <div>
                <FooterLogo src={logoSrc} />
            </div>
            <div>{t("shared.footer.copyright")}</div>
        </FooterWrapper>
    );
}

export default Footer;
