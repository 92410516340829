import { type CSSProperties, type JSX, type MutableRefObject, memo } from "react";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { chartColor } from "../../colors";
import { domainNticks, fmtTick } from "./MilesLineChart";
import { Legend, LegendLabel, LegendLine } from "./stylings";

export type IChartDatum =
    | {
          label: number;
      }
    | Record<string, number>;

type XAxisType = "day" | "week" | "month";
type RelativeType = "calendar" | "path";

export interface IProps {
    chartData: IChartDatum[];
    chartRef?: MutableRefObject<HTMLDivElement>;
    state: {
        xAxis: XAxisType;
        relative: RelativeType;
        maxTicks: number;
        dataLabels: boolean;
    };
    tasks: {
        id: string;
        shortTitle: string;
    }[];
    numMembers: number;
    style?: CSSProperties;
}

interface ILabelProps {
    x: number;
    y: number;
    stroke: string;
    value: number;
    index: number;
}

function renderLabel(props: ILabelProps, ticks: Set<number>, chartData: IChartDatum[]): JSX.Element | null {
    const { x, y, stroke, value, index } = props;
    if (!value) {
        return null;
    }
    const datum = chartData[index];
    if (!ticks.has(datum.label)) {
        return null;
    }
    return (
        <text x={x} y={y} dy={-4} dx={1} fill={stroke} fontSize={15} textAnchor="end">
            {value}
        </text>
    );
}

const margins = { top: 15, right: 5, bottom: 5, left: 5 };

function TaskCountLineChart({ chartData, chartRef, state, tasks, numMembers, style }: IProps): JSX.Element {
    const { xAxis, relative, dataLabels, maxTicks } = state;
    const type = xAxis === "day" ? "stepAfter" : "linear";
    const [domain, ticks] = domainNticks(chartData, maxTicks);
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" aspect={2}>
                    <LineChart data={chartData} margin={margins}>
                        <YAxis type="number" domain={[0, numMembers]} />
                        <XAxis
                            dataKey="label"
                            type="number"
                            domain={domain}
                            ticks={ticks}
                            tickFormatter={(val) => fmtTick(val, xAxis, relative)}
                            minTickGap={2}
                        />
                        {tasks.map((task, idx) => (
                            <Line
                                key={task.id}
                                isAnimationActive={false}
                                dataKey={task.id}
                                stroke={chartColor(idx)}
                                type={type}
                                strokeWidth={2}
                                dot={false}
                                label={dataLabels && ((props) => renderLabel(props, new Set(ticks), chartData))}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <Legend>
                {tasks.map((task, idx) => (
                    <LegendLabel key={task.id}>
                        <LegendLine $color={chartColor(idx)} />
                        {task.shortTitle}
                    </LegendLabel>
                ))}
            </Legend>
        </div>
    );
}

export default memo(TaskCountLineChart);
