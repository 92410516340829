import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import type { IExampleTask } from "./ExamplesModal";

interface IProps {
    task: IExampleTask;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 0.4rem;
    align-items: center;
    padding: 0.2rem 0.2rem;
    border: 2px solid ${currentColors.border};
    border-radius: 1rem;
    font-size: 0.85rem;
`;

const IconCol = styled.div`
    width: 50px;
    height: 50px;
    svg {
        width: 50px;
        height: 50px;
    }

`;

const NameCol = styled.div`
    h4 {
        font-size: 1.1rem;
        margin: 0;
    }
`;

function ExampleTask({ task }: IProps): JSX.Element {
    return (
        <Wrapper>
            <IconCol>
                <TaskIcon icon={task.icon} />
            </IconCol>
            <NameCol>
                <h4>{task.shortTitle}</h4>
                <div>{task.shortDescription}</div>
            </NameCol>
        </Wrapper>
    );
}

export default ExampleTask;
