import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../shared/colors";
import t from "../shared/translations";
import Logo from "./Logo";

const FooterElem = styled.footer`
    display: grid;
    grid-template-columns: 1fr;
    > * {
        text-align: center;
        font-size: 0.9em;
    }
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    padding-top: 0.3rem;
    gap: 0.5rem;
    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr;
    }
    border-top: 1px solid ${currentColors.grey4};
`;

const FooterLogo = styled(Logo)`
    flex-shrink: 0;
    height: 1.5rem!important;

    @media (min-width: 600px) {
        max-width: 33%;
    }
`;

function Footer(): JSX.Element {
    return (
        <FooterElem>
            <div>
                <FooterLogo />
            </div>
            <div>{t("shared.footer.copyright")}</div>
        </FooterElem>
    );
}

export default Footer;
