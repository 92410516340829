import { type CSSProperties, Fragment, type JSX, type MutableRefObject, memo } from "react";
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { chartColor } from "../../colors";
import { Legend, LegendLine } from "./stylings";

export type IChartDatum = Record<string, number>;

export interface IProps {
    chartData: IChartDatum[];
    questions: string[];
    areaHeight: number;
    chartRef?: MutableRefObject<HTMLDivElement>;
    style?: CSSProperties;
}

const AREA_HEIGHT = 0.5;

function makeDomain(idx: number, count: number, height: number): [number, number] {
    const areaHeight = height ?? AREA_HEIGHT;
    const revIdx = count - idx - 1;
    const start = 0 - areaHeight * revIdx;
    const end = areaHeight * (count - revIdx);
    return [start, end];
}

function SpreadChart({ chartData, questions, areaHeight, chartRef, style }: IProps): JSX.Element {
    console.log(questions.map((_, idx) => makeDomain(idx, questions.length, areaHeight)));
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" height={questions.length * 50 + 40}>
                    <AreaChart data={chartData}>
                        <defs>
                            {questions.map((_, idx) => (
                                <linearGradient id={`grad-${idx}`} key={idx} x1={0} y1={0} x2={0} y2={1}>
                                    <stop offset="20%" stopColor={chartColor(idx)} stopOpacity={1.0} />
                                    <stop offset="100%" stopColor={chartColor(idx)} stopOpacity={0.1} />
                                </linearGradient>
                            ))}
                        </defs>
                        <XAxis />
                        {questions.map((_, idx) => (
                            <YAxis
                                key={idx}
                                yAxisId={`axis-${idx}`}
                                width={0}
                                domain={makeDomain(idx, questions.length, areaHeight)}
                            />
                        ))}
                        {questions.map((question, idx) => (
                            <Area
                                fill={`url(#grad-${idx})`}
                                stroke={chartColor(idx)}
                                yAxisId={`axis-${idx}`}
                                key={idx}
                                dataKey={question}
                                isAnimationActive={false}
                                type="monotone"
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
            <Legend>
                {questions.map((question, idx) => (
                    <Fragment key={idx}>
                        <LegendLine $color={chartColor(idx)} />
                        <span style={{ gridColumn: "span 2" }}>{question}</span>
                    </Fragment>
                ))}
            </Legend>
        </div>
    );
}

export default memo(SpreadChart);
