import type { JSX } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import * as API from "../../api/Upload";
import AccountWizard, { type IValues } from "../../shared/components/AccountWizard";
import { useAlerts } from "../../shared/components/Alerts";
import { LinkStyleButton } from "../../shared/stylings";
import t from "../../shared/translations";
import type { IPage } from "./CreateAccount";
import type { IData } from "./index";
import {
    type IAccountInvitation,
    type ICreatePersonalArgs,
    type ICreatePersonalData,
    type ICreateReusableArgs,
    type ICreateReusableData,
    type IReusableAccountInvitation,
    createPersonalMutation,
    createReusableMutation,
} from "./queries";

interface IProps {
    data: IData;
    teamScan: boolean | null;
    email: string | null;
    reusable: boolean;
    invitation: IReusableAccountInvitation | IAccountInvitation;
    setPage: (page: IPage) => void;
}

const Main = styled.div`
    flex-grow: 1;
`;

function CreatePage({ data, setPage, teamScan, email, invitation, reusable }: IProps): JSX.Element {
    const [_, executePersonalMutation] = useMutation<ICreatePersonalData, ICreatePersonalArgs>(createPersonalMutation);
    const [__, executeReusableMutation] = useMutation<ICreateReusableData, ICreateReusableArgs>(createReusableMutation);
    const addAlert = useAlerts();

    const initialValues = {
        email: email ?? "",
    };

    const onPersonalSubmit = async (values: IValues) => {
        let filename: string | null = null;
        if (values.profilePicture) {
            try {
                filename = await API.uploadFile(values.profilePicture, "image", data.publicId);
            } catch (err) {
                console.error(err);
                addAlert(t("public.error.error"), "alert");
                return;
            }
        }
        const result = await executePersonalMutation({
            data: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                title: values.title,
                tel: values.tel,
                password: values.password,
                confirmPassword: values.confirmPassword,
                agreedToTerms: values.agreedToTerms,
                avatar: values.avatar,
                birthYear: values.birthYear,
                emailNotifications: values.emailNotifications,
                profilePicture: filename,
                publicId: data.publicId,
            },
        });
        if (result.error) {
            console.error(result.error);
            addAlert(t("public.error.error"), "alert");
        } else {
            if (result.data.accountInvitationCreateAccount.error) {
                console.error(result.data.accountInvitationCreateAccount.error);
                addAlert(result.data.accountInvitationCreateAccount.error, "alert");
            } else {
                window.location.href = result.data.accountInvitationCreateAccount.redirectUrl;
            }
        }
    };

    const onReusableSubmit = async (values: IValues) => {
        let filename: string | null = null;
        if (values.profilePicture) {
            try {
                filename = await API.uploadFile(values.profilePicture, "image", data.publicId);
            } catch (err) {
                console.error(err);
                addAlert(t("public.error.error"), "alert");
                return;
            }
        }
        const result = await executeReusableMutation({
            data: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                title: values.title,
                tel: values.tel,
                password: values.password,
                confirmPassword: values.confirmPassword,
                agreedToTerms: values.agreedToTerms,
                avatar: values.avatar,
                birthYear: values.birthYear,
                emailNotifications: values.emailNotifications,
                profilePicture: filename,
                publicId: data.publicId,
            },
        });
        if (result.error) {
            console.error(result.error);
            addAlert(t("public.error.error"), "alert");
        } else {
            if (result.data.reusableAccountInvitationCreateAccount.error) {
                console.error(result.data.reusableAccountInvitationCreateAccount.error);
                addAlert(result.data.reusableAccountInvitationCreateAccount.error, "alert");
            } else {
                window.location.href = result.data.reusableAccountInvitationCreateAccount.redirectUrl;
            }
        }
    };

    const onComplete = reusable ? onReusableSubmit : onPersonalSubmit;

    return (
        <Main>
            <h1>{t("public.create-account-create.heading", { title: data.webappTitle })}</h1>
            <p>
                {t("public.create-account-create.info-login-existing", { title: data.webappTitle })}{" "}
                <LinkStyleButton
                    onClick={() => {
                        setPage("Login");
                    }}
                >
                    {t("public.create-account-create.info-login-existing-link")}
                </LinkStyleButton>
            </p>
            {(invitation.learningPath || teamScan) && (
                <>
                    <div>{t("public.create-account-create.info-resource")}</div>
                    <ul>
                        {invitation.learningPath && (
                            <li>
                                {t("public.create-account-create.resource-path", { pathname: invitation.learningPath })}
                            </li>
                        )}
                        {teamScan && <li>{t("public.create-account-create.resource-team-scan")}</li>}
                    </ul>
                </>
            )}
            <AccountWizard
                initialValues={initialValues}
                onComplete={onComplete}
                avatarOptions={data.avatars}
                terms={data.terms}
                settingsTerms={data.settingsTerms}
            />
        </Main>
    );
}

export default CreatePage;
