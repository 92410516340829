import { type CSSProperties, type JSX, type MutableRefObject, memo } from "react";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { currentColors } from "../../colors";
import maxTextWidth from "../../maxTextWidth";
import t from "../../translations";
import { Legend, LegendLabel, LegendLine } from "./stylings";

export interface IChartDatum {
    label: string;
    value: number;
}

export interface IProps {
    chartData: IChartDatum[];
    checkpoint: number;
    goal: number;
    deadlineGoal: number;
    chartRef?: MutableRefObject<HTMLDivElement>;
    style?: CSSProperties;
}

function milesFormat(miles: number): string {
    if (!miles) {
        return "";
    }
    return miles.toFixed(0);
}

function tickFormatter(miles: number): string {
    return miles.toFixed(0);
}

const FONT = '400 15px / 1.5 "Proxima Nova", Verdana, sans-serif';

function MilesBarChart({ chartData, checkpoint, goal, deadlineGoal, chartRef, style }: IProps): JSX.Element {
    const height = chartData.length * 32 + 40;
    const showLegend = !!checkpoint || !!goal || !!deadlineGoal;
    const maxNameSize = maxTextWidth(
        chartData.map((datum) => datum.label),
        FONT,
    );
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" height={height}>
                    <BarChart data={chartData} layout="vertical">
                        <YAxis dataKey="label" type="category" width={maxNameSize + 20} tick={{ fontSize: 15 }} />
                        <XAxis type="number" tickFormatter={tickFormatter} />
                        <Bar
                            isAnimationActive={false}
                            dataKey="value"
                            fill={currentColors.theme1}
                            label={{ fill: "white", position: "insideRight", formatter: milesFormat, fontSize: 14 }}
                        />
                        {!!checkpoint && (
                            <ReferenceLine x={checkpoint} stroke={currentColors.grey4} strokeWidth={4} alwaysShow />
                        )}
                        {!!goal && (
                            <ReferenceLine x={goal} stroke={currentColors.taskDeadline} strokeWidth={4} alwaysShow />
                        )}
                        {!!deadlineGoal && (
                            <ReferenceLine x={deadlineGoal} stroke={currentColors.error} strokeWidth={4} alwaysShow />
                        )}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            {showLegend && (
                <Legend>
                    {!!checkpoint && (
                        <LegendLabel>
                            <LegendLine $color={currentColors.grey4} />
                            {t("shared.learning-path-activity-charts.label-checkbpoint")}
                        </LegendLabel>
                    )}
                    {!!goal && (
                        <LegendLabel>
                            <LegendLine $color={currentColors.taskDeadline} />
                            {t("shared.learning-path-activity-charts.label-goal")}
                        </LegendLabel>
                    )}
                    {!!deadlineGoal && (
                        <LegendLabel>
                            <LegendLine $color={currentColors.error} />
                            {t("shared.learning-path-activity-charts.label-deadline")}
                        </LegendLabel>
                    )}
                </Legend>
            )}
        </div>
    );
}

export default memo(MilesBarChart);
