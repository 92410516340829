import type { JSX } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Builder from "./Builder";
import LangPicker from "./LangPicker";
import Start from "./Start";

export type IBuilderParams = "lang" | "id";

function Redirector(): JSX.Element {
    const { lang, id } = useParams<IBuilderParams>();
    if (id) {
        return <Navigate to={`/path-builder/${lang}/build/${id}`} replace />;
    }
    return <Navigate to={`/path-builder/${lang}/start`} replace />;
}

function BuilderRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/path-builder/:lang/start/:id" element={<Start />} />
            <Route path="/path-builder/:lang/start" element={<Start />} />
            <Route path="/path-builder/:lang/build/:id" element={<Builder />} />
            <Route path="/path-builder/:lang/build" element={<Builder />} />
            <Route path="/path-builder/:lang/:id" element={<Redirector />} />
            <Route path="/path-builder/:lang" element={<Redirector />} />
            <Route path="/path-builder" element={<LangPicker />} />
        </Routes>
    );
}

export default BuilderRoutes;
