import Linkify from "linkify-react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import RenderMark from "../../shared/components/RenderMark";
import type { ITaskThreadItem } from "./OutsideFeedback";

interface IProps {
    tti: ITaskThreadItem;
}

const DEFAULT_IMG = "/static/images/profile-silouette.png";

const ThreadPic = styled.img`
    float: left;
    height: 45px;
    width: 45px;
    object-fit: cover;
    margin-right: 0.3rem;
`;

const ThreadName = styled.span`
    font-size: 0.9rem;
    font-weight: bold;
`;

interface IBox {
    $comment: boolean;
}

const Box = styled.div<IBox>`
    border: 1px solid ${({ $comment }) => ($comment ? currentColors.border : currentColors.text)};
    border-radius: 1rem;
    width: 80%;
    margin-bottom: 0.5rem;
    padding: 0.6rem;
    margin-left: ${({ $comment }) => ($comment ? "20%" : "0")};
    margin-right: ${({ $comment }) => ($comment ? "0" : "20%")};
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
`;

function ThreadItem({ tti }: IProps): JSX.Element {
    let content = <div />;
    if (tti.contentType === "markdown") {
        content = <RenderMark content={tti.content} key={tti.id} />;
    } else {
        content = (
            <Linkify options={{ nl2br: true }} tagName="p" key={tti.id}>
                {tti.content}
            </Linkify>
        );
    }
    return (
        <Box $comment={tti.type === "response"}>
            {tti.person && <ThreadPic src={tti.person.profilePicSrc || DEFAULT_IMG} />}
            {tti.person && (
                <ThreadName>
                    {tti.person.firstName} {tti.person.lastName}
                </ThreadName>
            )}
            {content}
        </Box>
    );
}

export default ThreadItem;
