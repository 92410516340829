import { type FormEvent, type JSX, memo, useCallback, useState } from "react";
import styled from "styled-components";
import { builderThemeColor, currentColors } from "../../shared/colors";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import CalendarIcon from "../../shared/components/icons/taskIcons/CalendarIcon";
import NetworkOfPeopleIcon from "../../shared/components/icons/taskIcons/NetworkOfPeopleIcon";
import PersonShadowIcon from "../../shared/components/icons/taskIcons/PersonShadowIcon";
import GlassesIcon from "../../shared/components/icons/teamScanIcons/GlassesIcon";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { TaskTheme } from "../../shared/types";
import type { ITaskType } from "./Tasks";

interface IProps {
    themes: TaskTheme[];
    taskType: ITaskType;
    onThemeChange: (event: FormEvent<HTMLInputElement>) => void;
    onTypeChange: (event: FormEvent<HTMLInputElement>) => void;
    setSort: ([ISortBy, ISortDir]) => void;
    toggleAllThemes: (on: boolean) => void;
}

const H4 = styled.h4`
    font-size: 1.2rem;
    margin: 0;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
`;

const FilterBox = styled.div`
    border: 1px solid ${currentColors.text};
    border-radius: 1rem;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0;
`;

const OptionLabel = styled.label`
    display: flex;
    gap: 4px;
    align-items: center;
    input {
        margin-bottom: 0;
    }
`;

const SelectLabel = styled.label`
    display: flex;
    gap: 5px;
    align-items: center;
    select {
        border: 1px solid ${currentColors.text};
        padding: 6px;
        border-radius: 15px;
        color: ${currentColors.text};
        width: auto;
        flex-grow: 1;
        margin-bottom: 0;
    }
`;

const ButtonRow = styled.div`
    button {
        padding: 4px 8px;
        margin: 0;
        font-size: 0.9rem;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem 0;
    @media (min-width: 680px) {
        grid-template-columns: 1fr auto 1fr;
    }
`;

const Line = styled.div`
    border-top: 1px solid ${currentColors.text};
    margin: 0.5rem 0;
    @media (min-width: 680px) {
        margin: 0 0.5rem;
        border-top: none;
        border-left: 1px solid ${currentColors.text};
    }
`;

const ThemeCol = styled.div`
`;

const ThemeBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 12px;
    margin: 5px 0;
`;

const TypeBox = styled.div`
    margin: 5px 0;
    svg {
        width: 18px;
        height: 18px;
    }
`;

function Filter({ themes, taskType, onThemeChange, setSort, toggleAllThemes, onTypeChange }: IProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [sorter, setSorter] = useState("shortTitle");

    const onSortChange = useCallback(
        (event: FormEvent<HTMLSelectElement>) => {
            const value = event.currentTarget.value;
            setSorter(value);
            if (value === "shortTitle") {
                setSort(["shortTitle", "increasing"]);
            } else if (value === "miles") {
                setSort(["miles", "decreasing"]);
            } else if (value === "theme") {
                setSort(["theme", "increasing"]);
            } else {
                setSort(["shortTitle", "increasing"]);
            }
        },
        [setSort],
    );

    return (
        <FilterBox>
            <H4 onClick={() => setOpen((o) => !o)}>
                {t("public.builder.heading-filter")}
                <PlusMinus open={open} size="1.2rem" />
            </H4>
            {open && (
                <>
                    <Row>
                        <ThemeCol>
                            <h5>{t("public.builder.heading-filter-themes")}</h5>
                            <ButtonRow>
                                <button onClick={() => toggleAllThemes(true)} type="button">
                                    {t("public.builder.form-filter-button-toggle-on")}
                                </button>{" "}
                                <button onClick={() => toggleAllThemes(false)} type="button">
                                    {t("public.builder.form-filter-button-toggle-off")}
                                </button>
                            </ButtonRow>
                            <ThemeBox>
                                {loadJSData().task_data.task_themes.map((thm) => (
                                    <OptionLabel key={thm}>
                                        <input
                                            type="checkbox"
                                            checked={themes.includes(thm)}
                                            onChange={onThemeChange}
                                            name={thm}
                                        />
                                        <svg
                                            viewBox="0 0 10 10"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="0.7rem"
                                            height="0.7rem"
                                        >
                                            <title>Theme color</title>
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4.5"
                                                fill={builderThemeColor[thm]}
                                                stroke={currentColors.text}
                                                strokeWidth="1"
                                            />
                                        </svg>
                                        {t(`shared.task-themes.${thm}`)}
                                    </OptionLabel>
                                ))}
                            </ThemeBox>
                        </ThemeCol>
                        <Line />
                        <TypeBox>
                            <h5>{t("public.builder.heading-filter-task-types")}</h5>
                            <OptionLabel>
                                <input
                                    type="radio"
                                    name="type"
                                    value="all"
                                    checked={taskType === "all"}
                                    onChange={onTypeChange}
                                />
                                {t("public.builder.form-filter-option-all")}
                            </OptionLabel>
                            <OptionLabel>
                                <input
                                    type="radio"
                                    name="type"
                                    value="group-task"
                                    checked={taskType === "group-task"}
                                    onChange={onTypeChange}
                                />
                                <PersonShadowIcon />
                                {t("public.builder.form-filter-option-is-group-task")}
                            </OptionLabel>
                            <OptionLabel>
                                <input
                                    type="radio"
                                    name="type"
                                    value="media"
                                    checked={taskType === "media"}
                                    onChange={onTypeChange}
                                />
                                <GlassesIcon />
                                {t("public.builder.form-filter-option-is-media")}
                            </OptionLabel>
                            <OptionLabel>
                                <input
                                    type="radio"
                                    name="type"
                                    value="people-event"
                                    checked={taskType === "people-event"}
                                    onChange={onTypeChange}
                                />
                                <NetworkOfPeopleIcon />
                                {t("public.builder.form-filter-option-is-people-event")}
                            </OptionLabel>
                            <OptionLabel>
                                <input
                                    type="radio"
                                    name="type"
                                    value="lecture"
                                    checked={taskType === "lecture"}
                                    onChange={onTypeChange}
                                />
                                <CalendarIcon />
                                {t("public.builder.form-filter-option-is-lecture")}
                            </OptionLabel>
                        </TypeBox>
                    </Row>
                    <SelectLabel>
                        <span>
                            {t("public.builder.form-filter-label-sort")}
                            {": "}
                        </span>
                        <select value={sorter} onChange={onSortChange}>
                            <option value="shortTitle">{t("public.builder.form-filter-option-short-title")}</option>
                            <option value="theme">{t("public.builder.form-filter-option-theme")}</option>
                            <option value="miles">{t("public.builder.form-filter-option-miles")}</option>
                        </select>
                    </SelectLabel>
                </>
            )}
        </FilterBox>
    );
}

export default memo(Filter);
