import type { JSX } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import { currentColors } from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import TaskDescription from "../../shared/components/TaskDescription";
import t from "../../shared/translations";
import type { ContentType, TaskIcon, TaskThreadItemSubtype, TaskThreadItemType } from "../../shared/types";
import Footer from "../Footer";
import type { IOutsideFeedbackParams } from "../PublicRoutes";
import FeedbackForm from "./FeedbackForm";
import Info from "./Info";
import Thread from "./Thread";

interface IArgs {
    id: string;
}

const query = `
    query($id:String!) {
        feedbackRequest(id:$id) {
            id
            description
            submittedTask {
                id
                icon
                shortTitle
                title
                description
                media {
                    id
                    mediaType
                    name
                    logo
                    src
                }
                person {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
                thread {
                    id
                    type
                    subtype
                    content
                    contentType
                    taskAnswers {
                        id
                        answerType
                        questionContent
                        answerContent
                        checkboxAnswers {
                            id
                            content
                            checked
                        }
                        taskLikertAnswers {
                            id
                            statement
                            leftLabel
                            rightLabel
                            value
                        }
                        taskAnswerFile {
                            id
                            name
                            src(publicId:$id)
                            logoSrc
                        }
                        impactTrackerAnswerSet {
                            id
                            impactLikertAnswers {
                                id
                                statement
                                leftLabel
                                rightLabel
                                value
                            }
                        }
                    }
                    person {
                        id
                        firstName
                        lastName
                        profilePicSrc
                    }
                }
            }
        }
    }
`;

export interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
}

type ITaskAnswer = {
    id: string;
    questionContent: string | null;
} & (
    | {
          answerType: "checkbox";
          checkboxAnswers: {
              id: string;
              content: string;
              checked: boolean;
          }[];
      }
    | {
          answerType: "likert";
          taskLikertAnswers: {
              id: string;
              statement: string;
              leftLabel: string;
              rightLabel: string;
              value: number;
          }[];
      }
    | {
          answerType: "impact-tracker";
          impactTrackerAnswerSet: {
              id: string;
              impactLikertAnswers: {
                  id: string;
                  statement: string;
                  leftLabel: string;
                  rightLabel: string;
                  value: number;
              }[];
          };
      }
    | {
          answerType: "textbox";
          answerContent: string | null;
      }
    | {
          answerType: "file";
          taskAnswerFile: {
              id: string;
              name: string;
              src: string;
              logoSrc: string;
          };
      }
);

export interface ITaskThreadItem {
    id: string;
    type: TaskThreadItemType;
    subtype: TaskThreadItemSubtype;
    content: string | null;
    contentType: ContentType | null;
    taskAnswers: ITaskAnswer[];
    person: IPerson | null;
}

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo?: string | null;
}

interface ISubmittedTask {
    id: string;
    icon: TaskIcon;
    shortTitle: string;
    title: string | null;
    description: string | null;
    media: IMedia[];
    thread: ITaskThreadItem[];
    person: IPerson;
}

interface IFeedbackRequest {
    id: string;
    description: string;
    submittedTask: ISubmittedTask;
}

interface IData {
    feedbackRequest: IFeedbackRequest;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
    min-height: 100vh;
`;

const Main = styled.main`
    flex-grow: 1;
`;

const H1 = styled.h1`
    padding: 0.5rem;
    margin: 0;
    margin-bottom: 2rem;
    background-color: ${currentColors.header};
    text-align: center;
    color: white;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
`;

function OutsideFeedback(): JSX.Element {
    const { publicId } = useParams<IOutsideFeedbackParams>();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id: publicId,
        },
    });
    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const { feedbackRequest } = result.data;
        const task = feedbackRequest.submittedTask;
        main = (
            <>
                <Info person={task.person} description={feedbackRequest.description} />
                <TaskDescription
                    shortTitle={task.shortTitle}
                    title={task.title}
                    description={task.description}
                    icon={task.icon}
                    media={task.media}
                    objectives={null}
                    miles={null}
                    inspiration={null}
                    invert={false}
                    taskVariants={[]}
                />
                <Thread thread={task.thread} />
                <FeedbackForm id={publicId} />
            </>
        );
    }
    return (
        <Wrapper>
            <header>
                <H1>{t("public.outside-feedback.heading")}</H1>
            </header>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default OutsideFeedback;
