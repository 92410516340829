import styled from "styled-components";
import { currentColors } from "../colors";

export const LinkStyleButton = styled.button.attrs({
    type: "button",
})`
    margin: 0;
    padding: 0;
    display: inline;
    color: ${currentColors.link};
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;
    
    &:hover, &:focus, &:active {
        color: ${currentColors.link};
        background-color: transparent;
    }
`;
