import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";

const Column = styled.div`
    display: grid;
    place-items: center;
    place-content: center;
    min-height: 100vh;
    gap: 0.5rem;
`;

const Logo = styled.img`
    height: 5rem;
    width: 290px;
    object-fit: scale-down;
`;

const ButtonLink = styled.a`
    display: block;
    padding: 1rem 2rem 1.0625rem;
    color: white;
    background-color: ${currentColors.button};
    width: 150px;
    text-align: center;

    &:hover, &:focus, &:active, &:visited {
        color: white;
    }
`;

function LangPicker(): JSX.Element {
    const logoSrc = loadJSData().settings.logo_src;
    const langs = loadJSData().languages;
    return (
        <Column>
            <h1>{t("public.builder-lang-picker.heading")}</h1>
            {langs.includes("en") && <ButtonLink href="/path-builder/en">{t("shared.language.en")}</ButtonLink>}
            {/* {langs.includes('de') && <ButtonLink href='/path-builder/de'>
            {t('shared.language.de')}
        </ButtonLink>}
        {/* {langs.includes('es') && <ButtonLink href='/path-builder/es'>
            {t('shared.language.de')}
        </ButtonLink>}
        {langs.includes('sv') && <ButtonLink href='/path-builder/sv'>
            {t('shared.language.sv')}
        </ButtonLink>}
        {langs.includes('fi') && <ButtonLink href='/path-builder/fi'>
            {t('shared.language.fi')}
        </ButtonLink>} */}
            <Logo src={logoSrc} />
        </Column>
    );
}

export default LangPicker;
