import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { AlertProvider } from "../../shared/components/Alerts";
import { JsDataGlobalNotice } from "../../shared/components/GlobalNotice";
import sentryExchange from "../../shared/sentryExchange";
import CreateAccount from "./CreateAccount";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/public",
});

export interface IData {
    publicId: string;
    terms: string;
    settingsTerms: string | null;
    avatars: string[];
    avatarPath: string;
    webappTitle: string;
}

// init function
export default function init() {
    const data = JSON.parse(document.getElementById("create-account-data").innerHTML) as IData;

    const container = document.getElementById("create-account-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <AlertProvider>
                <JsDataGlobalNotice />
                <CreateAccount data={data} />
            </AlertProvider>
        </URQLProvider>,
    );
}
