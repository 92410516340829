import { memo } from "react";
import type { ReactElement } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    tasks: {
        miles: number;
    }[];
    maxWidth: string;
}

interface IWrapper {
    $maxWidth: string;
}

const Wrapper = styled.div<IWrapper>`
    max-width: ${({ $maxWidth }) => $maxWidth};
    min-width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > svg {
        max-width: 140px;
    }
`;

const H3 = styled.h3`
    font-size: 1.35rem;
    text-align: center;
    margin-bottom: 0;
`;

function MilesChart({ tasks, maxWidth }: IProps): ReactElement {
    let miles: number | null = null;
    if (tasks.length > 0) {
        miles = tasks.reduce((acc, task) => acc + task.miles, 0);
    }
    return (
        <Wrapper $maxWidth={maxWidth}>
            <H3>{t("public.builder.heading-knowledge-miles-chart")}</H3>
            {miles !== null && (
                <ResponsiveContainer width="100%" aspect={0.75}>
                    <BarChart
                        data={[
                            {
                                label: t("public.builder.label-miles"),
                                value: miles,
                            },
                        ]}
                        margin={{
                            top: 15,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis
                            interval="preserveStartEnd"
                            domain={[0, (dataMax: number) => (dataMax > 2500 ? dataMax : 2500)]}
                            tick={{ fontSize: 13 }}
                            minTickGap={0}
                            tickCount={6}
                        />
                        <XAxis dataKey="label" tick={{ fontSize: 13 }} />
                        <Bar dataKey="value" isAnimationActive={false} fill={currentColors.builder} />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Wrapper>
    );
}

export default memo(MilesChart);
